<template>
  <button @click="swipe">
    <slot>
      <IconAngleLeft v-if="direction == 'prev'" />
      <IconAngleRight v-else />
    </slot>
  </button>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const props = defineProps<{
  direction: 'prev' | 'next'
}>()

const swiper = useSwiper()

const swipe = () => {
  if (props.direction === 'prev') {
    swiper.value.slidePrev()
  } else {
    swiper.value.slideNext()
  }
}
</script>
